import React, { useContext, useEffect, useState } from 'react';
// import fetchAPI from 'data/api';

import * as style from 'styles/components/utils/fiftyFiftyBanner.module.scss';
import { LangContext } from 'context/LangContext';
import Loader from 'components/utils/Loader';
import { getFormattedMessage } from 'components/utils/FormattedMessage';

import background from '../../images/lotty/lottery-wide-bg.jpg';

const FiftyFiftyBanner = ({ lottery }) => {
    const { lang } = useContext(LangContext);

    const lottyUrl = 'https://iqar.us/lotteries/';
    const lottyId = lottery.lotteryId;

    const [loading, setLoading] = useState(false);
    const [txtPitch, setTxtPitch] = useState(null);
    const [eventUrl, setEventUrl] = useState(null);
    const [opponent, setOpponent] = useState(null);
    const [prize, setPrize] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetch(lottyUrl + lottyId)
        .then(response => response.json())
        .then(response => {
            setTxtPitch(response.lottery.txt_pitch);
            setEventUrl(response.lottery.lottery_url);
            setOpponent(lottery.tmpOpponent);
            setPrize((response.lottery.sold_tickets_count * response.lottery.ticket_price) / 2);

            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching location:', error);
            setLoading(false);
        });

    }, []);

    return (
        <div className={style.banner}>
            {loading && (
                <div className={style.banner__loader}>
                    <Loader message={getFormattedMessage('global.loadingContent', lang)} />
                </div>
            )}
            {!loading && (
                <div className={style.banner__wrapper}>
                    <a href={eventUrl} label="Köp din lott här" className={style.banner__link} target="_blank" rel="noopener noreferrer">
                        <div className={style.banner__content}>
                            <div className={style.banner__header}>
                                <div className={style.banner__headerContent}>
                                    <h2 className="title-sm title-xs-mobile text-bold">Stöd Hammarby Ungdom<br />- köp 50/50 lotter</h2>
                                    <a href={eventUrl} className="cta-banner" target="_blank" rel="noopener noreferrer">Köp din lott här</a>
                                </div>
                            </div>
                            <div className={style.banner__body}>
                                <ul>
                                    <li>
                                        <h3 className="text-bold">Match:</h3>
                                        <div>Hammarby IF - {opponent}</div>
                                    </li>
                                    <li>
                                    <h3 className="text-bold">Prissumma:</h3>
                                    <div>{prize} kr</div>
                                    </li>
                                </ul>
                            </div>
                            <div className={style.banner__footer}>
                                {txtPitch}
                            </div>
                        </div>
                        <div className={style.banner__background}>
                            <div className={style.banner__gradient} />
                            <div className={style.banner__darken} />
                            <img src={background} alt="50/50 banner" />
                        </div>
                    </a>
                </div>
            )}
        </div>
    );
};

export default FiftyFiftyBanner;
